export function calculateWidth(currentWidth) {
	if (currentWidth < 1601) {
		if (currentWidth < 800) return null;
		else {
			return `${95 - (currentWidth - 800) * 0.0625}%`;
		}
	} else if (currentWidth > 1600) {
		return '50%';
	}
}
