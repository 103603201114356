import React, { useContext } from 'react';
import './Catagorias.css';
import CatagoriasButton from './CatagoriasButton/CatagoriasButton';
import PréstamosLogoButtonForCatagorias from '../../../images/PréstamosLogoButtonForCatagorias.png';
import SmartCoinsImage from '../../../images/SmartCoinsImage.png';
import CatagoriasTextParagraph from './CatagoriasTextParagraph/CatagoriasTextParagraph';
import { calculateWidth } from '../../../script.js';

import { Context } from '../../../App';
const Catagorias = () => {
	const currentWidth = useContext(Context);

	return (
		<div className="CatagoriasContainer">
			<div
				className="CatagoriasWrapper"
				style={{ width: calculateWidth(currentWidth) }}
			>
				<h2 className="CatagoriasTitle"> Nuestras Categorías</h2>
				<br />
				<div className="CatagoriasButtons">
					<CatagoriasButton
						text="Préstamos"
						image={PréstamosLogoButtonForCatagorias}
						redirectTo="/prestamos"
					/>
					{/* <CatagoriasButton
						text="second one!"
						image={PréstamosLogoButtonForCatagorias}
					/> */}
				</div>
				<h1>Como Funciona Compáralo:</h1>
				<div className="CatagoriasImageAndTextParagraphContainer">
					<span className="CatagoriasImageAndTextParagraphHalfTextContainer">
						<CatagoriasTextParagraph text="Comparalo te permite comparar las mejores opciones online." />
						<CatagoriasTextParagraph text="Compara las ofertas, elige tu mejor opción y contrata lo mejor para ti. " />
						<CatagoriasTextParagraph text="Nuestros sistemas de reseñas te permiten entender con precisión las experiencias de otros usuarios." />
					</span>
					<span className="CatagoriasImageAndTextParagraphHalfContainer">
						<img
							src={SmartCoinsImage}
							alt="SmartCoinsImage"
							className="CatagoriasImageStyle"
						></img>
					</span>
				</div>
			</div>
		</div>
	);
};

export default Catagorias;
