import React from 'react';
import './CatagoriasTextParagraph.css';
import BlueDot from '../../../../images/BlueDot.png';
const CatagoriasTextParagraph = (props) => {
	return (
		<div className="CatagoriasTextParagraphContainer">
			<img
				src={BlueDot}
				alt="BlueDot"
				className="CatagoriasTextParagraphImageStyle"
			/>
			<span>{props.text}</span>
		</div>
	);
};

export default CatagoriasTextParagraph;
