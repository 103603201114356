import React, { useContext } from 'react';
import './HomeHeadSection.css';
import SilverScales from '../../../images/SilverScales.png';
import { calculateWidth } from '../../../script.js';
import { Context } from '../../../App';
export const HomeHeadSection = () => {
	const currentWidth = useContext(Context);

	return (
		<div className="HomeHeadSectionBGImage">
			<div className="HomeHeadSectionContainer">
				<div
					className="HomeHeadSectionWrapper"
					style={{ width: calculateWidth(currentWidth) }}
				>
					<h2 className="HomeHeadSectionText">
						El Mejor comparador online en México.
						<br />
						Los mejores precios con las mejores condiciones para ti.
					</h2>
					<img
						src={SilverScales}
						alt="SilverScales"
						className="HomeHeadSectionImg"
					/>
				</div>
			</div>
		</div>
	);
};
