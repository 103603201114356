import React from 'react';
import './RecommendationTextExplain.css';
import approved_dot from '../../../../../images/approved_dot.png';
const RecommendationTextExplain = (props) => {
	return (
		<div className="RecommendationTextExplainWrapper">
			{props.data.map((item, index) => (
				<span
					className="RecommendationTextExplainSingleItemWrapper"
					key={`RecommendationTextExplainSingleItemWrapper${index}`}
				>
					<img
						src={approved_dot}
						alt="approved_dot"
						className="RecommendationTextExplainImage"
						key={`approved_dot${index}`}
					/>
					<span>{item}</span>
				</span>
			))}
		</div>
	);
};

export default RecommendationTextExplain;
