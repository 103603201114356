import React, { useContext } from 'react';
import './ExplainSection.css';
import ExplainSectionBoxs from './ExplainSectionBoxs/ExplainSectionBoxs';
import targetimage from '../../../images/targetimage.png';
import CupImage from '../../../images/CupImage.png';
import LikeImage from '../../../images/LikeImage.png';

import appOfStocksImg from '../../../images/appOfStocksImg.png';

import { calculateWidth } from '../../../script.js';
import { Context } from '../../../App';
const ExplainSection = () => {
	const currentWidth = useContext(Context);

	return (
		<div className="ExplainSectionContainer">
			<div
				className="ExplainSectionWrapper"
				style={{ width: calculateWidth(currentWidth) }}
			>
				<div className="ExplainSectionTextSide">
					<h1>Por que Compáralo es el mejor comparador online:</h1>
					<span>
						Nuestros sistemas de inteligencia artificial revisan cientos de
						elementos de cada opción para ofrecerte la más segura y competitiva.
					</span>
					<br /> <br />
					<br />
					<ExplainSectionBoxs
						text="Compáralo te ofrece seguridad, mejores precios y todas las opciones relevantes de tu búsqueda"
						image={targetimage}
					/>
					<ExplainSectionBoxs
						text="En nuestro sitio las opciones mas relevantes se exponen de forma
						totalmente objetiva y basada en la competitividad de las mejores
						opciones"
						image={CupImage}
					/>
					<ExplainSectionBoxs
						text="Trabajamos para ti. Para que tu experiencia de compra en línea sea la mejor"
						image={LikeImage}
					/>
					{/* <span>
					</span>
					<span>
						Trabajamos para ti. Para que tu experiencia de compra en línea sea
						la mejor.
					</span> */}
				</div>
				<span className="ExplainSectionImageSide">
					<img
						src={appOfStocksImg}
						alt="appOfStocksImg"
						className="ExplainSectionImageStyle"
					/>
				</span>
			</div>
		</div>
	);
};

export default ExplainSection;
