import './App.css';
import './Responsive.css';
import HomePage from './components/HomePage/HomePage';
import Prestamos from './components/Prestamos/Prestamos';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createContext, useState, useEffect } from 'react';
import data from './comparalo.json';
// import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
// import { getDatabase, ref, child, get } from 'firebase/database';
// import { firebaseConfig } from './config';

export const Context = createContext();

function App() {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [prestamosItems, setPrestamosItems] = useState({});

	useEffect(() => {
		let cb = function () {
			setWindowWidth(window.innerWidth);
		};
		window.addEventListener('resize', cb);

		return () => {
			window.removeEventListener('resize', cb);
		};
	}, []);
	useEffect(() => {
		setPrestamosItems(
			Object.values(data.prestamosItems)
				.filter((item) => item.position > 0)
				.sort((a, b) => a.position - b.position)
		);
		console.log(data.prestamosItems);
		// const appDB = initializeApp(firebaseConfig);
		// getAnalytics(appDB);
		// const dbRef = ref(getDatabase());

		// get(child(dbRef, `/`))
		// 	.then((snapshot) => {
		// 		if (snapshot.exists()) {
		// 			setPrestamosItems(
		// 				Object.values(snapshot.val().prestamosItems)
		// 					.filter((item) => item.position > 0)
		// 					.sort((a, b) => a.position - b.position)
		// 			);
		// 		}
		// 	})
		// 	.catch(async () => {
		// 		var getFromDBInterval = setInterval(() => {
		// 			get(child(dbRef, `/`)).then((snapshot) => {
		// 				if (snapshot.exists()) {
		// 					setPrestamosItems(
		// 						Object.values(snapshot.val().prestamosItems)
		// 							.filter((item) => item.position > 0)
		// 							.sort((a, b) => a.position - b.position)
		// 					);
		// 					clearInterval(getFromDBInterval);
		// 				}
		// 			});
		// 		}, 3000);
		// 	});
	}, []);
	return (
		<div className="App">
			<Context.Provider value={windowWidth}>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<HomePage />}></Route>
						<Route
							path="/prestamos"
							element={<Prestamos data={prestamosItems} />}
						></Route>
					</Routes>
				</BrowserRouter>
			</Context.Provider>
		</div>
	);
}

export default App;
