import React, { useContext } from 'react';
import './PrestamosHeadSection.css';
import moneyJump from '../../../images/moneyJump.png';
import { calculateWidth } from '../../../script';
import { Context } from '../../../App';
export const PrestamosHeadSection = () => {
	const currentWidth = useContext(Context);

	return (
		<div className="PrestamosHeadSectionBGImage">
			<div className="PrestamosHeadSectionContainer">
				<div
					className="PrestamosHeadSectionWrapper"
					style={{ width: calculateWidth(currentWidth) }}
				>
					<h2 className="PrestamosHeadSectionText">
						Compara las mejores opciones para
						<br />
						préstamos y obtén las mejores condiciones.
					</h2>
					<img
						src={moneyJump}
						alt="moneyJump"
						className="PrestamosHeadSectionImg"
					/>
				</div>
			</div>
		</div>
	);
};
