import React from 'react';
import './Starts.css';
import star_empty from '../../../../../images/star_empty.png';
import star_half_full from '../../../../../images/star_half_full.png';
import star_full from '../../../../../images/star_full.png';

const Starts = (props) => {
	return (
		<div className="StartsContainer">
			{props.stars_number % 1 === 0
				? Array(props.stars_number)
						.fill()
						.map((data, index) => (
							<img
								src={star_full}
								alt="star_full"
								key={`star_full${index}`}
								className="StarsImage"
							/>
						))
				: Array(parseInt(props.stars_number - (props.stars_number % 1)))
						.fill()
						.map((data, index) => (
							<img
								src={star_full}
								alt="star_full"
								key={`star_full${index}`}
								className="StarsImage"
							/>
						))}
			{props.stars_number % 1 !== 0 ? (
				Array(1)
					.fill()
					.map((data, index) => (
						<img
							src={star_half_full}
							alt="star_half_full"
							key={`star_half_full${index}`}
							className="StarsImage"
						/>
					))
			) : (
				<></>
			)}
			{props.stars_number <= 4 ? (
				Array(
					props.stars_number % 1 !== 0
						? 4 - props.stars_number + (props.stars_number % 1)
						: 5 - props.stars_number
				)
					.fill()
					.map((item, index) => (
						<img
							src={star_empty}
							alt="star_empty"
							className="StarsImage"
							key={`star_empty${index}`}
						/>
					))
			) : (
				<></>
			)}
		</div>
	);
};

export default Starts;
