import React from 'react';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import { PrestamosHeadSection } from './PrestamosHeadSection/PrestamosHeadSection';
import QASectionPrestamos from './QASectionPrestamos/QASectionPrestamos';
import Recommendations from './RecommendationsSection/Recommendations';
const Prestamos = (props) => {
	return (
		<div>
			<NavBar />
			<PrestamosHeadSection />
			<Recommendations data={props.data} />
			<QASectionPrestamos />
			<Footer />
		</div>
	);
};

export default Prestamos;
