import React from 'react';
import Footer from '../Footer/Footer';
import Catagorias from './Catagorias/Catagorias';
import ExplainSection from './ExplainSection/ExplainSection';
import { HomeHeadSection } from './HomeHeadSection/HomeHeadSection';
import NavBar from '../NavBar/NavBar';
import QASectionHome from './QASectionHome/QASectionHome';

const HomePage = () => {
	return (
		<div>
			<NavBar prestamos={true} />
			<HomeHeadSection />
			<Catagorias />
			<ExplainSection />
			<QASectionHome />
			<Footer />
		</div>
	);
};

export default HomePage;
