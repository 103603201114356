import React, { useContext } from 'react';
import RecommendationItem from './RecommendationItem/RecommendationItem';
import './Recommendations.css';

import { calculateWidth } from '../../../script';
import { Context } from '../../../App';

const Recommendations = (props) => {
	const currentWidth = useContext(Context);
	var today = new Date();
	const date =
		+today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

	if (Object.keys(props.data).length === 0 || props.data.length === 0) {
		return (
			<div className="loaderContainer">
				<div className="lds-roller">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="RecommendationsContainer">
				<div
					className="RecommendationsWrapper"
					style={{ width: calculateWidth(currentWidth) }}
				>
					<div className="TitleWrapper">
						<h2>Encuentra los mejores préstamos</h2>
						<h2 className="DateText">{date}</h2>
					</div>
					{Object.keys(props.data).length !== 0 ? (
						props.data.map((item, index) => (
							<RecommendationItem data={item} key={index} index={index} />
						))
					) : (
						<span />
					)}
				</div>
			</div>
		);
	}
};

export default Recommendations;
