import React, { useContext } from 'react';
import SingelQA from '../../SingelQA/SingelQA';
import './QASectionHome.css';
import { calculateWidth } from '../../../script.js';
import { Context } from '../../../App';
const QASectionHome = () => {
	const currentWidth = useContext(Context);

	return (
		<div className="QASectionHomeContainer">
			<div
				className="QASectionHomeWrapper"
				style={{ width: calculateWidth(currentWidth) }}
			>
				<h1>Preguntas frecuentes:</h1>
				<SingelQA
					question="Que es compáralo:"
					answer="Compáralo es el mejor comparador online en México."
				/>
				<SingelQA
					question="Como utilizo Compáralo:"
					answer="Encuentra la Categoría de tu búsqueda, llena la información de lo que estas buscando y obtén los mejores resultados."
				/>
				<SingelQA
					question="Debo inscribirme o pagar alguna membresía en Compáralo:"
					answer="El servicio de compáralo es y siempre será gratis para los usuarios."
				/>
				<SingelQA
					question="Cuando incluirá Compáralo otras categorías:"
					answer="Conforme nuestros usuarios solicitan nuevas categorías vamos creciendo para abarcarlas."
				/>
				<SingelQA
					question="Como me contacto con Compáralo:"
					answer="Envíanos un mail a servicio@comparalo.com o ventas@comparalo.com."
				/>
				<SingelQA
					question="Quien debería pedir un préstamo:"
					answer="Personas que quieren dar el empujón que un negocio, adquirir algún bien o invertir a nivel personal. Es importante siempre contar con la futura solvencia para pagar el préstamo "
				/>
				<SingelQA
					question="Puedo solicitar un préstamo en línea:"
					answer="Existen varias opciones en línea para solicitar un préstamo, ya sea personal o de negocio. Es importante siempre revisar bien las opciones y asegurarse de que las empresas que otorgan los créditos son empresas serias y conocidas. "
				/>
				<SingelQA
					question="Cual es la duración de un préstamo:"
					answer={
						<span>
							Cada préstamos es diferente en su cantidad, su duración, y su taza
							de interés. Existen préstamos de corto plazo (meses) así como
							préstamos a largo plazo (años).
							<br />
							La duración de los préstamos no debe ser mas larga de aquello que
							se esta financiando.
						</span>
					}
				/>
				<SingelQA
					question="Que es una taza de interés:"
					answer={
						<span>
							El tipo de interés o tasa de interés es el precio del dinero, es
							decir, es el precio por pagar por utilizar una cantidad de dinero
							durante un tiempo determinado.
							<br />
							Su valor indica el préstamosporcentaje de interés que se debe
							pagar como contraprestación por utilizar una cantidad determinada
							de dinero en una operación financiera.
						</span>
					}
				/>
				<SingelQA
					question="Tiene riesgo tomar un préstamo en línea:"
					answer={
						<span>
							Para no ser víctima de una estafa es importante aprender a
							identificar los préstamos fraudulentos y los falsos prestamistas
							que se encuentran 'online'.
							<br />
							De hecho, el mismo internet facilita en gran medida esta búsqueda
							de información para identificar qué entidad o persona es fiable.
							El inicio de año ha traído consigo un aumento de mensajes
							fraudulentos en aplicaciones como WhatsApp y Facebook de supuestas
							empresas que se hacen pasar por entidades crediticias y ofrecen
							préstamos con bajos intereses.
						</span>
					}
				/>
				<SingelQA
					question="Estas son algunas características de este tipo de mensajes que le ayudarán a identificarlos:"
					answer={
						<span>
							- El emisor suele ser una empresa extranjera.
							<br />- El mensaje, por lo general, no está escrito en un
							castellano correcto y entendible.
							<br />- Suelen tener un nombre poco común o extranjero.
							<br />- Solicitan abonar con anterioridad una suma para formalizar
							el contrato.
						</span>
					}
				/>
			</div>
		</div>
	);
};

export default QASectionHome;
