import React, { useContext } from 'react';
import SingelQA from '../../SingelQA/SingelQA';
import './QASectionPrestamos.css';

import { calculateWidth } from '../../../script.js';
import { Context } from '../../../App';
const QASectionPrestamos = () => {
	const currentWidth = useContext(Context);

	return (
		<div className="QASectionPrestamosContainer">
			<div
				className="QASectionPrestamosWrapper "
				style={{ width: calculateWidth(currentWidth) }}
			>
				<h1>Preguntas frecuentes:</h1>
				<SingelQA
					question="¿Que es un Préstamo?"
					answer={
						<span>
							El crédito o contrato de crédito es una operación financiera en la
							que una persona (el acreedor) realiza un préstamo por una cantidad
							determinada de dinero a otra persona (el deudor) y en la que este
							último, se compromete a devolver la cantidad solicitada (además
							del pago de los intereses devengados, seguros y costos asociados
							si los hubiere) en el tiempo o plazo definido de acuerdo a las
							condiciones establecidas para dicho préstamo.
						</span>
					}
				/>
				<SingelQA
					question="¿Quien debería pedir un préstamo?"
					answer="Personas que quieren dar el empujón que un negocio, adquirir algún bien o invertir a nivel personal. Es importante siempre contar con la futura solvencia para pagar el préstamo"
				/>
				<SingelQA
					question="¿Puedo solicitar un préstamo en línea?"
					answer="Existen varias opciones en línea para solicitar un préstamo, ya sea personal o de negocio. Es importante siempre revisar bien las opciones y asegurarse de que las empresas que otorgan los créditos son empresas serias y conocidas."
				/>
				<SingelQA
					question="¿Cual es la duración de un préstamo?"
					answer={
						<span>
							Cada préstamos es diferente en su cantidad, su duración, y su taza
							de interés. Existen préstamos de corto plazo (meses) así como
							préstamos a largo plazo (años).
							<br />
							La duración de los préstamos no debe ser mas larga de aquello que
							se esta financiando.
						</span>
					}
				/>
				<SingelQA
					question="¿Que es una taza de interés?"
					answer={
						<span>
							El tipo de interés o tasa de interés es el precio del dinero, es
							decir, es el precio por pagar por utilizar una cantidad de dinero
							durante un tiempo determinado.
							<br />
							Su valor indica el porcentaje de interés que se debe pagar como
							contraprestación por utilizar una cantidad determinada de dinero
							en una operación financiera.
						</span>
					}
				/>

				<SingelQA
					question="¿Tiene riesgo tomar un préstamo en línea?"
					answer={
						<span>
							Para no ser víctima de una estafa es importante aprender a
							identificar los préstamos fraudulentos y los falsos prestamistas
							que se encuentran 'online'. De hecho, el mismo internet facilita
							en gran medida esta búsqueda de información para identificar qué
							entidad o persona es fiable.
							<br />
							El inicio de año ha traído consigo un aumento de mensajes
							fraudulentos en aplicaciones como WhatsApp y Facebook de supuestas
							empresas que se hacen pasar por entidades crediticias y ofrecen
							préstamos con bajos intereses.
							<br />
							<br />
							Estas son algunas características de este tipo de mensajes que le
							ayudarán a identificarlos:
							<br />
							<br />- El emisor suele ser una empresa extranjera.
							<br />
							- El mensaje, por lo general, no está escrito en un castellano
							correcto y entendible.
							<br />
							- Suelen tener un nombre poco común o extranjero.
							<br />
							- Solicitan abonar con anterioridad una suma para formalizar el
							contrato.
							<br />
						</span>
					}
				/>
			</div>
		</div>
	);
};

export default QASectionPrestamos;
