import React, { useContext } from 'react';
import './Footer.css';
import ComparaloWhiteLogo from '../../images/ComparaloWhiteLogo.png';
import { calculateWidth } from '../../script.js';
import { Context } from '../../App';
const Footer = () => {
	const currentWidth = useContext(Context);
	return (
		<div className="FooterContainer">
			<div className="FooterWrapper">
				<div
					className="FooterImageWrapper"
					style={{ width: calculateWidth(currentWidth) }}
				>
					<img src={ComparaloWhiteLogo} alt="ComparaloWhiteLogo" />
					<span />
				</div>
			</div>
			<div className="FooterText">
				© 2017 Comparalo | todos los derechos reservados.
			</div>
		</div>
	);
};

export default Footer;
