import React, { useState } from 'react';
import './RecommendationItem.css';
import Starts from './Starts/Starts';
import RecommendationTextExplain from './RecommendationTextExplain/RecommendationTextExplain';
const RecommendationItem = (props) => {
	const [hovered, setHovered] = useState(false);
	const onMouseEnter = (e) => {
		setHovered(true);
	};

	const onMouseLeave = (e) => {
		setHovered(false);
	};

	return props.data ? (
		<div
			className={
				props.index === 0
					? 'RecommendationItemContainer goldBorder'
					: 'RecommendationItemContainer'
			}
			onClick={() => {
				window.open(
					`${props.data.link_url}${
						props.data.link_url.includes('?') ? '&' : '?'
					}${props.data.link_parameter}=${localStorage.getItem('gclid')}`,
					'_blank'
				);
			}}
		>
			<span className="RecommendationItemNumberRank">{props.index + 1}</span>
			{props.index === 0 ? (
				<span className="RecommendationItemRecommended">Recommendado</span>
			) : (
				<></>
			)}
			<div
				className="RecommendationItemWrapper"
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				<span className="RecommendationItemLeftSideWrapper">
					<img
						src={props.data.icon_image_base64}
						alt={props.data.name}
						className="RecommendationItemLogoImage"
					/>
					<span className="RecommendationItemLeftSideWrapperStarsAndCount">
						<Starts stars_number={props.data.stars_number} />
						<span>{`(${props.data.reviews_number.toLocaleString(
							'en-US'
						)} reseñas)`}</span>
					</span>
				</span>
				<span className="RecommendationTextExplainSection">
					<RecommendationTextExplain data={props.data.array_of_texts} />
				</span>

				<span className="RecommendationItemRate">{props.data.rate_number}</span>
				<span className="RecommendationItemRateText">
					{props.data.rate_text}
				</span>
				<span>
					<span>
						<div
							className={
								hovered
									? 'RecommendationItemButtonLink RecommendationItemButtonLinkGlow'
									: 'RecommendationItemButtonLink'
							}
						>
							{props.data.link_text}
						</div>
					</span>
				</span>
			</div>
		</div>
	) : (
		<span />
	);
};

export default RecommendationItem;
