import React from 'react';
import { useNavigate } from 'react-router-dom';

import './CatagoriasButton.css';
const CatagoriasButton = (props) => {
	let navigate = useNavigate();

	return (
		<span
			className="CatagoriasButtonContainer"
			onClick={() => {
				navigate(`/prestamos`);
			}}
		>
			<img
				src={props.image}
				alt={`${props.text}`}
				className="CatagoriasButtonImage"
			/>

			{props.text}
		</span>
	);
};

export default CatagoriasButton;
