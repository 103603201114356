import React from 'react';
import './NavBar.css';
import comparaloLogo from '../../images/Comparalo-logo.png';
import { useNavigate } from 'react-router-dom';

const NavBar = (props) => {
	let navigate = useNavigate();

	return (
		<div className="NavBarContainer">
			<img src={comparaloLogo} id="comparaloLogo" alt="comparaloLogo" />
			{props.prestamos ? (
				<span
					className="NavBarTextLinks"
					onClick={() => {
						navigate(`/prestamos`);
					}}
				>
					Préstamos
				</span>
			) : (
				<span className="NavBarTextLinksNotVisable">Préstamos</span>
			)}

			{/* <h3 className="NavBarTextLinks">Tarjeta de crédito</h3> */}
		</div>
	);
};

export default NavBar;
