import React from 'react';
import './ExplainSectionBoxs.css';
const ExplainSectionBoxs = (props) => {
	return (
		<div className="ExplainSectionBoxsContainer">
			<span className="ExplainSectionBoxsImageWrapper">
				<img src={props.image} alt={props.text} />
			</span>
			<div className="ExplainSectionBoxsTextWrapper">{props.text}</div>
		</div>
	);
};

export default ExplainSectionBoxs;
